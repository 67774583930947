import { defineStore } from "pinia";
import { ref, } from "vue";

interface dashboard_info {
    dashboard_emails_lastday: string,
    dashboard_emails_lastfivemin: string,
    dashboard_emails_lasthour: string,
    dashboard_emails_queue: string,
    dashboard_emails_status: string,
    dashboard_payments_amount_lastday: string,
    dashboard_payments_amount_lasthour: string,
    dashboard_payments_number_lastday: string,
    dashboard_payments_number_lasthour: string,
    dashboard_refunds_amount_lastday: string,
    dashboard_refunds_amount_lasthour: string,
    dashboard_refunds_number_lastday: string,
    dashboard_refunds_number_lasthour: string,
    dashboard_registrations_lastday: string,
    dashboard_registrations_lastfivemin: string,
    dashboard_registrations_lasthour: string,
    dashboard_sms_lastday: string,
    dashboard_sms_lastfivemin: string,
    dashboard_sms_lasthour: string,
    dashboard_sms_queue: string,
    dashboard_sms_status: string,
    dashboard_mailgun_failed_lastday: string,
    dashboard_mailgun_failed_lasthour: string
}
interface email_badge {
    review: string,
    support: string
}
export const useDashboardStore = defineStore("dashboard", () => {
    let data = ref(history)

    let dashboard = ref<dashboard_info>();
    function setDashboard(info: any) {
        dashboard.value = { ...info };
    }
    let email_badge = ref<email_badge>();
    function setEmailBadge(info: any) {
        email_badge.value = { ...info };
    }
    return {
        dashboard,
        email_badge,
        setDashboard,
        setEmailBadge,
        data
    }
})
