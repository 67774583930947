import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid dashboard-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col-sm-12 col-lg-12 col-xl-12" }
const _hoisted_5 = { class: "table-responsive theme-scrollbar" }
const _hoisted_6 = { class: "table table-dashed" }
const _hoisted_7 = { class: "d-flex gap-2" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "row"
}
const _hoisted_12 = { class: "col-sm-12 col-lg-12 col-xl-12" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = {
  style: {"width":"100%"},
  cellspacing: "2",
  cellpadding: "2"
}
const _hoisted_15 = { style: {"width":"10%"} }
const _hoisted_16 = { class: "d-flex flex-column gap-2" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { style: {"width":"5%"} }
const _hoisted_21 = { style: {"width":"10%"} }
const _hoisted_22 = { style: {"width":"10%"} }
const _hoisted_23 = { style: {"width":"7%"} }
const _hoisted_24 = { style: {"width":"10%"} }
const _hoisted_25 = { style: {"width":"14%"} }
const _hoisted_26 = { class: "d-flex flex-column" }
const _hoisted_27 = { style: {"width":"14%"} }
const _hoisted_28 = { class: "d-flex flex-column" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-sm-12 col-lg-12 col-xl-12" }
const _hoisted_31 = { class: "table-responsive theme-scrollbar" }
const _hoisted_32 = { class: "table table-dashed" }
const _hoisted_33 = { class: "d-flex gap-2" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = ["onClick"]
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "d-flex flex-column" }
const _hoisted_38 = { style: {"font-size":"10px"} }

import {
  GetSystemDashboard,
  GetEmailQueue,
  GetSMSQueue,
  ResendEmailMailgun,
  ResendSMS,
  DeleteQueueEntry,
  GetMailGunFailedQueue,
  ResendEmailSendGrid,
} from "@/services/ApiCore";
import { defineAsyncComponent, onMounted, ref } from "vue";
import { openModal } from "@kolirt/vue-modal";
import { useDashboardStore } from "@/store/dashboard";
import { useCommonStore } from "@/store/common";
import { toast } from "vue3-toastify";

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexDefault',
  setup(__props) {

const ManageEntry = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/ManageEntry.vue")
);
const Registration = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Registration.vue")
);
const Email = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Email.vue")
);
const SMS = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/SMS.vue")
);
const Elavon = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Elavon.vue")
);
const store = useDashboardStore();
const commonStore = useCommonStore();
let emails = ref<any>([]);
let smss = ref<any>([]);
let mailguns = ref<any>([]);

onMounted(() => {
  (async () => {
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
});
function handleOnSMSQueue() {
  (async () => {
    emails.value = [];
    mailguns.value = [];
    smss.value = await GetSMSQueue();
  })();
}
function handleOnMailGunFailedQueue() {
  (async () => {
    smss.value = [];
    emails.value = [];
    mailguns.value = await GetMailGunFailedQueue();
  })();
}
function handleOnEmailQueue() {
  (async () => {
    smss.value = [];
    mailguns.value = [];
    emails.value = await GetEmailQueue();
  })();
}
function handleOnReload() {
  (async () => {
    commonStore.toggleLoader();
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
}
function handleResend(type: string, ID: any) {
  (async () => {
    if (type == "sms") {
      await ResendSMS(ID);
      toast.success("Sent SMS ", {
        position: "top-right",
        autoClose: 2000,
      });
      handleOnSMSQueue();
    } else if (type == "email") {
      await ResendEmailMailgun(ID);
      toast.success("Sent Email ", {
        position: "top-right",
        autoClose: 2000,
      });
      handleOnEmailQueue();
    } else if (type == "email_mailgun" || type == "email_sendgrid") {
      if (type == "email_mailgun") {
        await ResendEmailMailgun(ID);
        toast.success("Sent Email ", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (type == "email_sendgrid") {
        await ResendEmailSendGrid(ID);
        toast.success("Sent Email ", {
          position: "top-right",
          autoClose: 2000,
        });
      }

      handleOnMailGunFailedQueue();
    } else {
    }
  })();
}
function handleDelete(type: string, ID: any) {
  (async () => {
    await DeleteQueueEntry(type, ID);
    if (type == "sms") {
      handleOnSMSQueue();
    } else if (type == "email") {
      handleOnEmailQueue();
    } else {
    }
  })();
}
function handleEdit(type: string, ID: any) {
  openModal(ManageEntry, {
    type,
    ID,
  })
    // runs when modal is closed via confirmModal
    .then((data) => {
      console.log("success", data);
      if (data == "email") {
        handleOnEmailQueue();
      } else if (data == "sms") {
        handleOnSMSQueue();
      } else {
      }
    })
    // runs when modal is closed via closeModal or esc
    .catch(() => {
      console.log("catch");
    });
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Dashboard",
      defaultdesc: "true",
      mains: "Dashboard",
      title: "Default"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Registration), { onOnReload: handleOnReload }),
        _createVNode(_unref(Email), {
          onOnReload: handleOnReload,
          onOnEmailQueue: handleOnEmailQueue,
          onOnMailgunFailed: handleOnMailGunFailedQueue
        }),
        _createVNode(_unref(SMS), {
          onOnReload: handleOnReload,
          onOnSmsQueue: handleOnSMSQueue
        }),
        _createVNode(_unref(Elavon), { onOnReload: handleOnReload })
      ]),
      (_unref(emails).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("table", _hoisted_6, [
                  _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", { scope: "col" }, "Action"),
                      _createElementVNode("th", { scope: "col" }, "Subject"),
                      _createElementVNode("th", { scope: "col" }, "From Email"),
                      _createElementVNode("th", { scope: "col" }, "From Name"),
                      _createElementVNode("th", { scope: "col" }, "To Email"),
                      _createElementVNode("th", { scope: "col" }, "To Name"),
                      _createElementVNode("th", { scope: "col" }, "Client"),
                      _createElementVNode("th", { scope: "col" }, "Event")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(emails), (email, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `email-${index}`
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _withModifiers(($event: any) => (handleEdit('email', email.ID)), ["stop"]),
                              class: "btn btn-sm btn-light text-dark btn-action"
                            }, " Edit ", 8, _hoisted_8),
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _withModifiers(($event: any) => (handleResend('email', email.ID)), ["stop"]),
                              class: "btn btn-sm btn-light text-dark btn-action"
                            }, " Resend ", 8, _hoisted_9),
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-sm btn-danger text-white btn-action",
                              onClick: _withModifiers(($event: any) => (handleDelete('email', email.ID)), ["stop"])
                            }, " Delete ", 8, _hoisted_10)
                          ])
                        ]),
                        _createElementVNode("td", null, _toDisplayString(email.queue_email_subject), 1),
                        _createElementVNode("td", null, _toDisplayString(email.queue_email_from_email), 1),
                        _createElementVNode("td", null, _toDisplayString(email.queue_email_from_name), 1),
                        _createElementVNode("td", null, _toDisplayString(email.queue_email_to_email), 1),
                        _createElementVNode("td", null, _toDisplayString(email.queue_email_to_name), 1),
                        _createElementVNode("td", null, _toDisplayString(email.queue_email_client), 1),
                        _createElementVNode("td", null, _toDisplayString(email.queue_email_event), 1)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(mailguns).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("table", _hoisted_14, [
                  _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"10%"}
                      }, "Action"),
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"5%"}
                      }, "Date"),
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"10%"}
                      }, "To Email"),
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"10%"}
                      }, "Subject"),
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"7%"}
                      }, "Reason"),
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"10%"}
                      }, "From Email"),
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"14%"}
                      }, "Client"),
                      _createElementVNode("th", {
                        scope: "col",
                        style: {"width":"14%"}
                      }, "Event")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mailguns), (mailgun, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `mailgun-${index}`
                      }, [
                        _createElementVNode("td", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-sm btn-light text-dark btn-action",
                              onClick: _withModifiers(($event: any) => (handleEdit('email_failed', mailgun.ID)), ["stop"])
                            }, " Edit ", 8, _hoisted_17),
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _withModifiers(($event: any) => (handleResend('email_mailgun', mailgun.ID)), ["stop"]),
                              class: "btn btn-sm btn-light text-dark btn-action"
                            }, " Resend Mailgun ", 8, _hoisted_18),
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _withModifiers(($event: any) => (handleResend('email_sendgrid', mailgun.ID)), ["stop"]),
                              class: "btn btn-sm btn-light text-dark btn-action"
                            }, " Resend SendGrid ", 8, _hoisted_19)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_20, _toDisplayString(mailgun.queue_email_addeddate), 1),
                        _createElementVNode("td", _hoisted_21, _toDisplayString(mailgun.queue_email_to_email), 1),
                        _createElementVNode("td", _hoisted_22, _toDisplayString(mailgun.queue_email_subject), 1),
                        _createElementVNode("td", _hoisted_23, _toDisplayString(mailgun.reason_message), 1),
                        _createElementVNode("td", _hoisted_24, _toDisplayString(mailgun.queue_email_from_email), 1),
                        _createElementVNode("td", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createTextVNode(_toDisplayString(mailgun.queue_email_client) + " ", 1),
                            _createElementVNode("strong", null, _toDisplayString(mailgun.queue_email_client_abbreviation), 1)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createTextVNode(_toDisplayString(mailgun.queue_email_event) + " ", 1),
                            _createElementVNode("strong", null, _toDisplayString(mailgun.queue_email_event_name), 1)
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(smss).length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _cache[3] || (_cache[3] = _createTextVNode("x ")),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("table", _hoisted_32, [
                    _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { scope: "col" }, "Action"),
                        _createElementVNode("th", { scope: "col" }, "To"),
                        _createElementVNode("th", { scope: "col" }, "From"),
                        _createElementVNode("th", { scope: "col" }, "Message"),
                        _createElementVNode("th", { scope: "col" }, "Code"),
                        _createElementVNode("th", { scope: "col" }, "Client"),
                        _createElementVNode("th", { scope: "col" }, "Event"),
                        _createElementVNode("th", { scope: "col" }, "Added Date")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(smss), (sms, index) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: `sms-${index}`
                        }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_33, [
                              _createElementVNode("button", {
                                type: "button",
                                onClick: _withModifiers(($event: any) => (handleEdit('sms', sms.ID)), ["stop"]),
                                class: "btn btn-sm btn-light text-dark btn-action"
                              }, " Edit ", 8, _hoisted_34),
                              _createElementVNode("button", {
                                type: "button",
                                class: "btn btn-sm btn-light text-dark btn-action",
                                onClick: _withModifiers(($event: any) => (handleResend('sms', sms.ID)), ["stop"])
                              }, " Resend ", 8, _hoisted_35),
                              _createElementVNode("button", {
                                type: "button",
                                class: "btn btn-sm btn-danger text-white btn-action",
                                onClick: _withModifiers(($event: any) => (handleDelete('sms', sms.ID)), ["stop"])
                              }, " Delete ", 8, _hoisted_36)
                            ])
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_37, [
                              _createElementVNode("span", null, _toDisplayString(sms.queue_sms_to), 1),
                              _createElementVNode("span", _hoisted_38, _toDisplayString(sms.queue_sms_phone), 1)
                            ])
                          ]),
                          _createElementVNode("td", null, _toDisplayString(sms.queue_sms_from), 1),
                          _createElementVNode("td", null, _toDisplayString(sms.queue_sms_message), 1),
                          _createElementVNode("td", null, _toDisplayString(sms.queue_sms_code), 1),
                          _createElementVNode("td", null, _toDisplayString(sms.queue_sms_client), 1),
                          _createElementVNode("td", null, _toDisplayString(sms.queue_sms_event), 1),
                          _createElementVNode("td", null, _toDisplayString(sms.queue_sms_AddedDate), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})