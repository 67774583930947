<template>
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Edit Template Design [{{ template_description }}]</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex justify-content-end gap-2">
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click.stop="handleCancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click.stop="handleSave"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="editor-wrapper">
        <div id="editor-container" class="w-full"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#editor-container::v-deep {
  height: 100%;
  iframe {
    height: 100%;
    min-width: 100% !important;
  }
}
.editor-wrapper {
  height: 660px !important;
}
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;
  &:hover {
    border: 1px solid #a20000;
  }
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>
<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref, watch } from "vue";
import { GetEmailByTemplate, SaveTemplateDesign } from "@/services/ApiCore";
import { useRoute, useRouter } from "vue-router";
let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let template_description = ref<string>("");
let router = useRouter();
const route = useRoute();
watch(
  () => route.params.template_id,
  async (templateID) => {
    initEditor();
  }
);
function handleCancel() {
  router.push(`/administration/templates-easyreg/list`);
}
function handleSave() {
  unlayer.exportHtml(async (data: any) => {
    var json = data.design; // design json
    var html = data.html; // design html

    const foundIndex = json.body.rows.findIndex(
      (x: any) => x.id == "maincontent"
    );
    let mainHtmlText =
      json.body.rows[foundIndex].columns[0].contents[0].values.text;

    console.log("json", json);
    // Save the json, or html here
    await SaveTemplateDesign(
      JSON.stringify(json),
      mainHtmlText,
      html,
      route.params.template_id.toString()
    );
    router.push(`/administration/templates-easyreg/list`);
  });
}

function loadEditor() {
  try {
    const editor = unlayer.init({
      id: "editor-container",
      projectId: 90684,
      displayMode: "email",
    });
    window.editor = editor;
  } catch (err) {
    console.log("load editor", err);
  }
}
function loadDesign(json: any) {
  if (json) {
    unlayer.loadDesign(JSON.parse(json));
    unlayer.addEventListener("design:updated", (updates: any) => {
      // Design is updated by the user
      console.log("design:updated");
      console.log("updates", updates);
      unlayer.exportHtml(
        (data) => {
          var json = data.design; // design json
          var html = data.html; // design html

          console.log("html", html);
          console.log("json", json);
          currentJson.value = JSON.stringify(json);
          currentHtml.value = html;
          // Save the json, or html here
        },
        { minify: true }
      );
    });
  }
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      const { json, html, subject, description } = await GetEmailByTemplate(
        route.params.template_id.toString()
      );
      template_description.value = description;
      currentJson.value = json;
      currentHtml.value = html;
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
onMounted(() => {
  (async () => {
    console.log("route.params.template_id", route.params.template_id);
    initEditor();
  })();
});
</script>