import { defineStore } from 'pinia'
import { ref } from 'vue'
import { computed } from 'vue'
import { sent, starred, draft, trash, work, support } from "@/core/data/emails"

export const useCommonStore = defineStore('Common', () => {
    let isLoading = ref<boolean>(false);
    let isStar = ref<boolean>(false);
    let sents = ref(sent)
    let starr = ref(starred)
    let items = ref(draft)
    let deletes = ref(trash)
    let works = ref(work)
    let supported = ref(support)
    let isSent = ref<string>("");
    let template = ref<string>("");


    const toggleLoader = () => {
        isLoading.value = true;
    };
    function toggleSent(result: string) {
        isSent.value = result;
    }
    const toggleSetStar = () => {
        if (isStar.value) {
            isStar.value = false;
        } else {
            isStar.value = true;
        }
    };
    function setTemplate(tpl: any) {
        template.value = tpl;
    }
    return {
        isLoading,
        sents,
        starr,
        items,
        deletes,
        works,
        supported,
        toggleLoader,
        toggleSetStar,
        isStar,
        toggleSent,
        isSent,
        template,
        setTemplate
    }
})
