<template>
  <Breadcrumbs main="New Email" mains="New Email" title="New Email" />
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-9 col-xl-9 box-col-9">
          <div class="email-right-aside">
            <div class="user-footer">
              <div class="toolbar-box mb-2">
                <div class="row mb-1">
                  <div class="col-sm-12 px-0 py-0">
                    <div class="card-wrapper py-0 d-flex gap-2">
                      <label> From </label>

                      <button
                        type="button"
                        class="btn btn-sm"
                        :class="
                          send_from_email == 'martin@easyreg.ca'
                            ? 'btn-primary text-light'
                            : 'btn-light text-dark'
                        "
                        @click.stop="send_from_email = 'martin@easyreg.ca'"
                      >
                        martin@easyreg.ca
                      </button>

                      <button
                        type="button"
                        class="btn btn-sm"
                        :class="
                          send_from_email == 'help@easyreg.ca'
                            ? 'btn-primary text-light'
                            : 'btn-light text-dark'
                        "
                        @click.stop="send_from_email = 'help@easyreg.ca'"
                      >
                        help@easyreg.ca
                      </button>
                    </div>
                  </div>
                </div>
                <div class="gap-2 needs-validation">
                  <div class="row mb-1">
                    <div class="col-sm-12 px-0 py-0">
                      <div
                        class="card-wrapper py-0 d-flex gap-2 position-relative"
                      >
                        <label> To </label>
                        <autocomplete
                          style="width: 40%"
                          :search="search"
                          :debounceTime="500"
                          placeholder="Search By Email"
                          :get-result-value="getResultValue"
                        >
                          <template #result="{ result, props }">
                            <li v-bind="props">
                              <div class="wiki-title">
                                <span class="badge bg-primary text-white"
                                  >{{ result.firstname }} {{ result.lastname }}
                                </span>
                              </div>
                              <div class="wiki-snippet" v-html="result.email" />
                            </li>
                          </template>
                        </autocomplete>
                        <div class="d-flex gap-1">
                          <template
                            v-for="(send_to_email, index) in send_to_emails"
                            :key="`send-to-email-${index}`"
                          >
                            <div class="email-item d-flex gap-1">
                              {{
                                `${send_to_email.name}<${send_to_email.email}>`
                              }}
                              <button
                                class="btn-close"
                                type="button"
                                @click.stop="
                                  handleDelete(
                                    send_to_email.email,
                                    'send_to_emails'
                                  )
                                "
                              ></button>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="gap-2 needs-validation">
                  <div class="row mb-1">
                    <div class="col-sm-12 px-0 py-0">
                      <div
                        class="card-wrapper py-0 d-flex gap-2 position-relative"
                      >
                        <label> CC </label>
                        <autocomplete
                          style="width: 40%"
                          :search="search"
                          :debounceTime="500"
                          placeholder="Search By Email"
                          :get-result-value="getResultCCValue"
                        >
                          <template #result="{ result, props }">
                            <li v-bind="props">
                              <div class="wiki-title">
                                <span class="badge bg-primary text-white"
                                  >{{ result.firstname }} {{ result.lastname }}
                                </span>
                              </div>
                              <div class="wiki-snippet" v-html="result.email" />
                            </li>
                          </template>
                        </autocomplete>
                        <div class="d-flex gap-1">
                          <template
                            v-for="(send_cc_email, index) in send_cc_emails"
                            :key="`send-cc-email-${index}`"
                          >
                            <div class="email-item d-flex gap-1">
                              {{
                                `${send_cc_email.name}<${send_cc_email.email}>`
                              }}
                              <button
                                class="btn-close"
                                type="button"
                                @click.stop="
                                  handleDelete(
                                    send_cc_email.email,
                                    'send_cc_emails'
                                  )
                                "
                              ></button>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="gap-2 needs-validation">
                  <div class="row mb-1">
                    <div class="col-sm-12 px-0 py-0">
                      <div
                        class="card-wrapper py-0 d-flex gap-2 position-relative"
                      >
                        <label> BCC </label>
                        <autocomplete
                          style="width: 40%"
                          :search="search"
                          :debounceTime="500"
                          placeholder="Search By Email"
                          :get-result-value="getResultBCCValue"
                        >
                          <template #result="{ result, props }">
                            <li v-bind="props">
                              <div class="wiki-title">
                                <span class="badge bg-primary text-white"
                                  >{{ result.firstname }} {{ result.lastname }}
                                </span>
                              </div>
                              <div class="wiki-snippet" v-html="result.email" />
                            </li>
                          </template>
                        </autocomplete>
                        <div class="d-flex gap-1">
                          <template
                            v-for="(send_bcc_email, index) in send_bcc_emails"
                            :key="`send-bcc-email-${index}`"
                          >
                            <div class="email-item d-flex gap-1">
                              {{
                                `${send_bcc_email.name}<${send_bcc_email.email}>`
                              }}
                              <button
                                class="btn-close"
                                type="button"
                                @click.stop="
                                  handleDelete(
                                    send_bcc_email.email,
                                    'send_bcc_emails'
                                  )
                                "
                              ></button>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-1 needs-validation">
                  <div class="col-sm-12 px-0 py-0">
                    <div
                      class="card-wrapper py-0 d-flex gap-2 position-relative"
                    >
                      <label class="col-form-label">Subject</label>
                      <input
                        class="form-control"
                        id="composeFrom"
                        type="text"
                        v-model="to_subject"
                        v-bind:class="
                          formSubmitted
                            ? to_subject == ''
                              ? 'is-invalid'
                              : 'is-valid'
                            : ''
                        "
                      />
                      <div
                        class="invalid-tooltip"
                        v-if="formSubmitted && to_subject == ''"
                      >
                        Subject is empty!
                      </div>
                    </div>
                  </div>
                </div>
                <div class="editor-wrapper">
                  <div id="editor-container" class="w-full"></div>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-start px-2"
              >
                <div class="mt-2 pt-2">
                  <div class="d-flex flex-column attachment-wrapper gap-2">
                    <template
                      v-for="(
                        send_attachment, send_attachment_index
                      ) in send_attachments"
                      :key="`attachment-index-${send_attachment_index}`"
                    >
                      <div
                        class="d-flex justify-content-between gap-2 attachment-item"
                      >
                        <div class="d-flex gap-1">
                          <span class="filename">{{
                            send_attachment.name
                          }}</span>
                          <span
                            >({{
                              $filters.filesize(send_attachment.size)
                            }})</span
                          >
                        </div>

                        <button
                          class="btn-close btn-sm"
                          @click.stop="handleRemoveAttachment(send_attachment)"
                        ></button>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="send-btn d-flex gap-2 justify-content-end mb-4">
                  <button
                    class="btn btn-secondary d-flex gap-2"
                    type="button"
                    @click="handleAttachFiles"
                  >
                    Attach files<i class="fa-solid fa-paperclip"></i>
                  </button>
                  <button
                    class="btn btn-primary d-flex gap-2"
                    type="button"
                    @click="handleSend"
                  >
                    Send<i class="fa-solid fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-3 box-col-3 p-0">
          <div class="email-right-aside p-2">
            <div class="p-2 pb-0 article-wrapper">
              <span class="text">Search Articles</span>
            </div>
            <div class="p-2 search-wrapper">
              <div class="d-flex align-items-center gap-2">
                <i class="search-bg iconly-Search icli"></i>
                <input
                  class="demo-input py-0 Typeahead-input form-control-plaintext w-100"
                  type="text"
                  placeholder="Search for keywords or title"
                  title=""
                  @keyup="searchArticles"
                  v-model="keyword_for_article"
                />
                <template v-if="keyword_for_article != ''">
                  <span
                    @click.stop="keyword_for_article = ''"
                    class="cursor-pointer close-btn d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </template>
              </div>
            </div>

            <template v-if="searched_articles.length > 0">
              <div
                class="card email-body email-read show gap-2 article-wrapper"
              >
                <template
                  v-for="(searched_article, index) in searched_articles"
                  :key="`searched-article-${index}`"
                >
                  <div
                    class="flex flex-column align-items-start p-2 article-searched-wrapper"
                  >
                    <div class="d-flex flex-column">
                      <div
                        class="d-flex gap-2 justify-between-content article-headline"
                      >
                        <span class="assigned_name flex-1">{{
                          searched_article.article_headline
                        }}</span>
                        <button
                          type="button"
                          @click.stop="
                            handleInsert(searched_article.article_id)
                          "
                          class="btn btn-md btn-light text-dark btn-receipt"
                        >
                          Insert
                        </button>
                      </div>
                    </div>
                    <div>
                      <span class="article-content d-flex flex-column gap-1">
                        <div v-html="searched_article.article_text"></div>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <input type="file" accept="*" hidden ref="uploadRef" @change="uploadFile" />
  </div>
</template>
<style lang="scss" scoped>
.email-item {
  border: 1px solid #308e87;
  border-radius: 18px;
  background: #308e87;
  color: #fff;
  padding: 3px 8px;
  font-size: 13px;
}
.searched-wrapper {
  background: #ececec;
  .text {
    color: #7bcc00;
    font-weight: 800;
    font-size: 18px;
  }
}
.email-right-aside {
  background: #fff;
}
.search-wrapper {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  .close-btn {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.article-wrapper {
  background: none;
  .text {
    color: #7bcc00;
    font-weight: 800;
    font-size: 18px;
  }
  .assigned_name {
    font-size: 13px;
    font-weight: 800;
  }
}
.article-searched-wrapper {
  border-bottom: 3px solid #7bcc00;
  .article-headline {
    width: 100%;
    border-bottom: 1px solid #7bcc00;
    justify-content: space-between !important;
    align-items: start;
    .assigned_name {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: 800;
    }
  }
  .btn-receipt {
    border: 1px solid #7bcc00;
    float: right;
  }
  .article-content {
    height: 37px;
    font-weight: 300;
    letter-spacing: 0.3;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: var(--font-color);
      font-weight: 400;
      line-height: 1.9;
    }
  }
}
.email-right-aside {
  background: #fff;
}
.assigned-wrapper {
  border-bottom: 2px solid #7bcc00;
  .assigned_event {
    font-weight: 600;
  }
  .assigned_name {
    font-size: 17px;
    font-weight: 800;
  }
  .btn-receipt {
    border: 1px solid #7bcc00;
  }
  .assigned_code {
    font-size: 18px;
    font-weight: 800;
    align-items: end !important;
  }
}
.attachment-wrapper {
  max-width: 530px;
  .attachment-item {
    width: 100%;
    font-weight: 600;
    .filename {
      flex: 1;
      color: blue;
      max-width: 320px;
      display: inline-block;
      overflow: hidden;
      padding: 3px 0;
      text-overflow: ellipsis;
      vertical-align: bottom;
      white-space: nowrap;
    }
    background: #ececec;
    padding: 5px 20px;
  }
}
.btn-reply {
  display: flex;
  border: 1px solid #730000;
  &:hover {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:focus {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:before {
    background-image: url(//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_baseline_nv700_20dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    content: "";
  }
}
.btn-reply-all {
  display: flex;
  border: 1px solid #730000;
  &:hover {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:focus {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:before {
    background-image: url(//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_all_baseline_nv700_20dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    content: "";
  }
}
.toolbar-box {
  padding: 5px !important;
  // border: 1px solid #730000;
}
.mail-subcontent::v-deep {
  height: 400px;
  iframe {
    html {
      body {
        font-family: sans-serif;
      }
    }
  }
}
#editor-container::v-deep {
  height: 610px !important;
}
.flex-1 {
  flex: 1;
}
.editor-wrapper {
  height: 610px !important;
}
</style>
<script lang="ts" setup>
import {
  defineAsyncComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  useTemplateRef,
  watch,
} from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import {
  GetArticles,
  GetEmailByTemplate,
  GetSearchByKeyword,
  GetSearchSystemByKeyword,
  ReturnDesignJsonByAddTemplate,
  SendEmailUsingNylas,
} from "@/services/ApiCore";
import Vue3TagsInput from "vue3-tags-input";
import TopolPlugin from "@topol.io/editor";
let searched_articles = ref<any>([]);
let keyword_for_article = ref<string>("");
let commonStore = useCommonStore();
const { template: template } = storeToRefs(commonStore);
watch(
  () => template,
  () => {
    editor_loaded.value = false;
    setTimeout(() => {
      editor_loaded.value = true;
      nextTick(() => {
        if (commonStore.template) {
          initEditor();
        }
      });
    }, 200);
  },
  { deep: true }
);
let customOptions = ref<any>();
const uploadRef = useTemplateRef("uploadRef");
let editor_loaded = ref<boolean>(false);
let send_from_email = ref<any>("martin@easyreg.ca");
let to_subject = ref<string>("");
let send_attachments = ref<any>([]);
let send_to_emails = ref<any>([]);
let send_cc_emails = ref<any>([]);
let send_bcc_emails = ref<any>([]);
let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let formSubmitted = ref<boolean>(false);
let sendingEmail = ref<boolean>(false);
let vvalue = ref<any>([]);
import { useRoute, useRouter } from "vue-router";
let router = useRouter();
function loadEditor() {
  const TOPOL_OPTIONS = {
    id: "#editor-container",
    enableAutosaves: true,
    autosaveInterval: 30,
    authorize: {
      apiKey: process.env.TOPOL_API_KEY,
      userId: 1,
    },
    light: true,
    premadeBlocks: [
      {
        name: "Online Attendee Site",
        blocks: [
          {
            img: "https://2023-easyreg-prod.s3.ca-central-1.amazonaws.com/attachments/online_attendee_site_premade_block.png", // Image url, for best experience use width > 330 px
            name: "Online Attendee Site Block", // Or name if not image available
            // MJML JSON
            definition: [
              {
                tagName: "mj-section",
                attributes: {
                  "full-width": "600px",
                  padding: "10px 0px 10px 0px",
                  "mj-class": "section",
                },
                type: null,
                children: [
                  {
                    tagName: "mj-column",
                    attributes: {
                      width: "100%",
                      "vertical-align": "top",
                    },
                    children: [
                      {
                        tagName: "mj-text",
                        content:
                          '<p>You can access the Online Attendee Site using the following link:<br>###attendee.accesslink###</p>\n          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">OR</p>\n          <ul>\n              <li>Go to https://online.ourconference.events</li>\n              <li>Enter ###attendee.email### under Email Address</li>\n              <li>Enter ###attendee.code### under Code</li>\n              <li>Click Login</li>\n          </ul>',
                        attributes: {
                          padding: "10px 10px",
                          align: "left",
                          "line-height": 1.3,
                          containerWidth: 600,
                        },
                        uid: "H1lqIiX4lm",
                      },
                    ],
                    uid: "SJ3I0XVx7",
                  },
                ],
                layout: 1,
                backgroundColor: "",
                backgroundImage: "",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                uid: "rkqIjQNe7",
              },
            ],
          },
        ],
      },
    ],
    customBlocks: [],
    callbacks: {
      async onSave(json: any, html: any) {
        console.log("json", json);
        console.log("html", html);
        currentJson.value = json;
        currentHtml.value = html;

        if (sendingEmail.value) {
          formSubmitted.value = true;
          try {
            if (send_from_email.value == "") {
              sendingEmail.value = false;
              return;
            }
            if (to_subject.value == "") {
              sendingEmail.value = false;
              return;
            }
            const { result } = await SendEmailUsingNylas(
              "",
              send_from_email.value,
              JSON.stringify(send_to_emails.value),
              JSON.stringify(send_cc_emails.value),
              JSON.stringify(send_bcc_emails.value),
              to_subject.value,
              currentHtml.value,
              send_attachments.value
            );
            commonStore.toggleSent(result);
            sendingEmail.value = false;
            if (result == "success") {
              router.push("/app/emails/sent");
            } else {
            }
          } catch (e) {
            sendingEmail.value = false;
            console.log("handleSend error", e);
          }
        }
      },
      onLoaded(json: any, html: any) {
        TopolPlugin.save();
      },
    },
  };
  TopolPlugin.init(TOPOL_OPTIONS);
}
function loadDesign(json: any) {
  if (json) {
    TopolPlugin.load(json);
  }
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      let template = "BLANK";
      if (commonStore.template) {
        template = commonStore.template;
      }
      const { json, html, subject } = await GetEmailByTemplate(template);
      if (subject != "" && subject != null) {
        to_subject.value = subject;
      }
      currentJson.value = json;
      currentHtml.value = html;
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
function handleAttachFiles() {
  if (uploadRef.value) {
    uploadRef.value.click();
  }
}
function handleSend() {
  (async () => {
    sendingEmail.value = true;
    TopolPlugin.save();
  })();
}
function generateId(len: any) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr).join("");
}
async function loadFile(files: any) {
  const filePromises = Array.from(files).map((file: any) => {
    // Return a promise per file
    return new Promise((resolve, reject) => {
      let response = {};
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          response = {
            handleId: generateId(20),
            pathname: file.name,
            name: file.name,
            size: file.size,
            type: file.type,
            image: null,
            file: file,
            uploaded: "B",
          };
          // Resolve the promise with the response value
          resolve(response);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });
  const fileInfos = await Promise.all(filePromises);
  console.log("fileInfos", fileInfos);
  return fileInfos;
}
async function uploadFile(e: any) {
  if (e.target.files.length === 0) return;
  console.log("e.target.files", e.target.files);
  const result = await loadFile(e.target.files);
  console.log("result", result);
  send_attachments.value = [...send_attachments.value, ...result];
}
function handleRemoveAttachment(file: any) {
  send_attachments.value = send_attachments.value.filter(
    (x: any) => x.handleId != file.handleId
  );
}
async function searchArticles(e: any) {
  if (e.which == 13) {
    if (keyword_for_article.value != "") {
      const { rows } = await GetArticles("keyword", keyword_for_article.value);
      searched_articles.value = rows;
    }
    e.preventDefault();
  }
}
function handleInsert(article_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        article_id
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
async function search(keyword: any) {
  return new Promise(async (resolve) => {
    if (keyword.length < 5) {
      return resolve([]);
    }
    const res = await GetSearchByKeyword(keyword);
    resolve(res);
  });
}
function getResultValue(result: any) {
  send_to_emails.value = [
    ...send_to_emails.value,
    { name: `${result.firstname} ${result.lastname}`, email: result.email },
  ];
}
function getResultCCValue(result: any) {
  send_cc_emails.value = [
    ...send_cc_emails.value,
    { name: `${result.firstname} ${result.lastname}`, email: result.email },
  ];
}
function getResultBCCValue(result: any) {
  send_bcc_emails.value = [
    ...send_bcc_emails.value,
    { name: `${result.firstname} ${result.lastname}`, email: result.email },
  ];
}
function handleDelete(email: string, type: string) {
  if (type == "send_to_emails") {
    send_to_emails.value = send_to_emails.value.filter(
      (x: any) => x.email != email
    );
  } else if (type == "send_cc_emails") {
    send_cc_emails.value = send_cc_emails.value.filter(
      (x: any) => x.email != email
    );
  } else if (type == "send_bcc_emails") {
    send_bcc_emails.value = send_bcc_emails.value.filter(
      (x: any) => x.email != email
    );
  } else {
  }
}
onUnmounted(() => {
  TopolPlugin.destroy();
});
onMounted(() => {
  sendingEmail.value = false;
  send_to_emails.value = [];
  send_cc_emails.value = [];
  send_bcc_emails.value = [];
  formSubmitted.value = false;
  keyword_for_article.value = "";
  searched_articles.value = [];
  send_from_email.value = "martin@easyreg.ca";
  editor_loaded.value = false;
  setTimeout(() => {
    editor_loaded.value = true;
    nextTick(() => {
      initEditor();
    });
  }, 200);
});
</script>