import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6 col-12" }
const _hoisted_5 = { class: "col-sm-6 col-12 d-flex justify-content-end gap-2" }

import { defineAsyncComponent, onMounted, ref, watch } from "vue";
import { GetEmailByTemplate, SaveTemplateDesign } from "@/services/ApiCore";
import { useRoute, useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'EditTemplates',
  setup(__props) {

let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let template_description = ref<string>("");
let router = useRouter();
const route = useRoute();
watch(
  () => route.params.template_id,
  async (templateID) => {
    initEditor();
  }
);
function handleCancel() {
  router.push(`/administration/templates-easyreg/list`);
}
function handleSave() {
  unlayer.exportHtml(async (data: any) => {
    var json = data.design; // design json
    var html = data.html; // design html

    const foundIndex = json.body.rows.findIndex(
      (x: any) => x.id == "maincontent"
    );
    let mainHtmlText =
      json.body.rows[foundIndex].columns[0].contents[0].values.text;

    console.log("json", json);
    // Save the json, or html here
    await SaveTemplateDesign(
      JSON.stringify(json),
      mainHtmlText,
      html,
      route.params.template_id.toString()
    );
    router.push(`/administration/templates-easyreg/list`);
  });
}

function loadEditor() {
  try {
    const editor = unlayer.init({
      id: "editor-container",
      projectId: 90684,
      displayMode: "email",
    });
    window.editor = editor;
  } catch (err) {
    console.log("load editor", err);
  }
}
function loadDesign(json: any) {
  if (json) {
    unlayer.loadDesign(JSON.parse(json));
    unlayer.addEventListener("design:updated", (updates: any) => {
      // Design is updated by the user
      console.log("design:updated");
      console.log("updates", updates);
      unlayer.exportHtml(
        (data) => {
          var json = data.design; // design json
          var html = data.html; // design html

          console.log("html", html);
          console.log("json", json);
          currentJson.value = JSON.stringify(json);
          currentHtml.value = html;
          // Save the json, or html here
        },
        { minify: true }
      );
    });
  }
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      const { json, html, subject, description } = await GetEmailByTemplate(
        route.params.template_id.toString()
      );
      template_description.value = description;
      currentJson.value = json;
      currentHtml.value = html;
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
onMounted(() => {
  (async () => {
    console.log("route.params.template_id", route.params.template_id);
    initEditor();
  })();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h2", null, "Edit Template Design [" + _toDisplayString(_unref(template_description)) + "]", 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: _withModifiers(handleCancel, ["stop"])
            }, " Cancel "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: _withModifiers(handleSave, ["stop"])
            }, " Save ")
          ])
        ])
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "row" }, [
        _createElementVNode("div", { class: "editor-wrapper" }, [
          _createElementVNode("div", {
            id: "editor-container",
            class: "w-full"
          })
        ])
      ])
    ], -1))
  ], 64))
}
}

})