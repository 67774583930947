import ApiInstance from "./ApiBase";

export const GetArticles = async (): Promise<any> => {
    const res = await ApiInstance.get(`/articles/all/1`);
    return res?.data;
};

export const GetArticle = async (id: number): Promise<any> => {
    const res = await ApiInstance.get(`/article/${id}`);
    return res?.data;
};

export const ManageArticle = async (): Promise<any> => {
    const res = await ApiInstance.post(`/articles/manage}`);
    return res?.data;
};
