import ApiInstance from "./ApiBase";

export const GetEmailList = async (page: number, category: string, star: string = 'N'): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-list/${page}/${category}/${star}`);
    return res?.data;
};

export const GetEmailCategoryBadge = async (): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-category-badge`);
    return res?.data;
};

export const GetEmailSync = async (tab: string, emails_id: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-sync/${tab}/${emails_id}`);
    return res?.data;
};
export const GetSearchSystemByKeyword = async (keyword: string): Promise<any> => {
    // const res = await ApiInstance.get(`http://localhost:8888/api/checkEmailCRMByKeyword/${keyword}`);
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/checkEmailCRMByKeyword/${keyword}`);
    return res?.data;
};
export const GetSearchByKeyword = async (
    email: string,
): Promise<any> => {
    const data = new FormData();
    data.append("email", email);
    // const res = await ApiInstance.post(`http://localhost:8888/api/checkFullEmailCRM`, data);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/checkFullEmailCRM`, data);
    return res?.data;
};
export const GetAssignsByEmail = async (emails_id: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-assigns-by-email/${emails_id}`);
    return res?.data;
};
export const GetArticles = async (type: string, keyword: string): Promise<any> => {
    const res = await ApiInstance.get(`/articles/${type}/${keyword}`);
    return res?.data;
};

export const GetInitDesign = async (emails_id: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-init-design/${emails_id}`);
    return res?.data;
};
export const ReturnDesignJsonByAddTemplate = async (
    json: string,
    article_id: any
): Promise<any> => {
    const data = new FormData();
    data.append("json", json);
    data.append("article_id", article_id);
    const res = await ApiInstance.post(`return-design-json-by-add-template`, data);
    return res?.data;
};


export const SendEmailUsingNylas = async (
    emails_id: string,
    from_email: string,
    to_emails: string,
    cc_emails: string,
    bcc_emails: string,
    subject: string,
    body: string,
    attachments: any
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("from_email", from_email);
    data.append("to_emails", to_emails);
    data.append("cc_emails", cc_emails);
    data.append("bcc_emails", bcc_emails);
    data.append("subject", subject);
    data.append("body", body);
    for (var idx = 0; idx < attachments.length; idx++) {
        data.append(`attachments-${idx}`, attachments[idx].file);
    }
    const res = await ApiInstance.post(`send-email-using-nylas`, data);
    return res?.data;
};

export const SetEmailClose = async (
    emails_id: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    const res = await ApiInstance.post(`set-email-close`, data);
    return res?.data;
};

export const SetEmailStar = async (
    emails_id: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    const res = await ApiInstance.post(`set-email-star`, data);
    return res?.data;
};

export const SetEmailCategory = async (
    emails_id: string,
    category: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("category", category);
    const res = await ApiInstance.post(`set-email-category`, data);
    return res?.data;
};

export const SaveRule = async (
    rule_type: string,
    rule_text: string,
    rule_category: string,
): Promise<any> => {
    const data = new FormData();
    data.append("rule_type", rule_type);
    data.append("rule_text", rule_text);
    data.append("rule_category", rule_category);
    const res = await ApiInstance.post(`save-rule`, data);
    return res?.data;
};

export const GetSystemDashboard = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getSystemDashboard`);
    return res?.data;
};

export const GetEmailQueue = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getEmailQueue`);
    return res?.data;
};

export const GetSMSQueue = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getSMSQueue`);
    return res?.data;
};

export const GetMailGunFailedQueue = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getMailGunFailedQueue`);
    return res?.data;
};

export const GetEmailQueueByID = async (ID: any, type: string): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getEmailQueueByID/${ID}/${type}`);
    return res?.data;
};

export const GetSMSQueueByID = async (ID: any): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getSMSQueueByID/${ID}`);
    return res?.data;
};
export const ResendSMS = async (
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/resend-sms`, data);
    return res?.data;
};
export const ResendEmailSendGrid = async (
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/resend-email-sendgrid`, data);
    return res?.data;
};
export const ResendEmailMailgun = async (
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/resend-email-mailgun`, data);
    return res?.data;
};

export const DeleteQueueEntry = async (
    type: string,
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("type", type);
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/deleteQueueEntry`, data);
    return res?.data;
};


export const SaveQueueEntry = async (
    type: string,
    ID: string,
    value: string
): Promise<any> => {
    const data = new FormData();
    data.append("type", type);
    data.append("ID", ID);
    data.append("value", value);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/saveQueueEntry`, data);
    return res?.data;
};

export const AssignEmail = async (
    emails_id: string,
    assign_id: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("assign_id", assign_id);
    const res = await ApiInstance.post(`assign-email`, data);
    return res?.data;
};


export const AssignEmailBySearched = async (
    emails_id: string,
    item: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("item", item);
    const res = await ApiInstance.post(`assign-email-by-searched`, data);
    return res?.data;
};


export const GetEmailTemplates = async (page: number, keyword: string = 'all'): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-templates/${page}/${keyword}`);
    return res?.data;
};
export const GetDesignByTemplate = async (template: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-design-by-template/${template}`);
    return res?.data;
};
export const GetEmailByTemplate = async (template: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-by-template/${template}`);
    return res?.data;
};

export const SaveEmailByTemplate = async (
    template_id: string,
    template_code: string,
    template_description: string,
    template_subject: string,
    template_sender_name: string,
    template_sender_email: string,
    template_edit_type: string
): Promise<any> => {
    const data = new FormData();
    data.append("template_id", template_id);
    data.append("template_code", template_code);
    data.append("template_description", template_description);
    data.append("template_subject", template_subject);
    data.append("template_sender_name", template_sender_name);
    data.append("template_sender_email", template_sender_email);
    data.append("template_edit_type", template_edit_type);
    const res = await ApiInstance.post(`save-email-by-template`, data);
    return res?.data;
};

export const DeleteEmailByTemplate = async (template: string): Promise<any> => {
    const res = await ApiInstance.delete(`/delete-email-by-template/${template}`);
    return res?.data;
};


export const SaveTemplateDesign = async (
    json: string,
    html: string,
    content: string,
    template_id: string,
): Promise<any> => {
    const res = await ApiInstance.post(`/save-template-design`, {
        json: json,
        html: html,
        content: content,
        template_id: template_id,
    });
    return res?.data;
};
