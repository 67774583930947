<template>
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Templates</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click.stop="handleCreate"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 box-col-12">
          <div class="email-right-aside">
            <div class="row">
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="table-responsive theme-scrollbar">
                  <table class="table table-dashed">
                    <thead>
                      <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(template, index) in allData"
                        :key="`template-${index}`"
                      >
                        <td>
                          <div class="d-flex gap-2">
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click.stop="
                                handleEdit(template.template_ID, 'edit')
                              "
                            >
                              Edit Subject
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click.stop="handleContent(template.template_ID)"
                            >
                              Edit Content
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click.stop="
                                handleEdit(template.template_ID, 'clone')
                              "
                            >
                              Clone Content
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger text-white btn-action"
                              @click.stop="handleDelete(template.template_ID)"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                        <td>{{ template.template_subject }}</td>
                        <td>{{ template.template_description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;
  &:hover {
    border: 1px solid #a20000;
  }
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>
<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { DeleteEmailByTemplate, GetEmailTemplates } from "@/services/ApiCore";
import { openModal } from "@kolirt/vue-modal";
let currentPage = ref<number>(1);
let allData = ref<any[]>([]);
import { useRoute, useRouter } from "vue-router";
let router = useRouter();
const ManageTemplate = defineAsyncComponent(
  () => import("@/components/modal/ManageTemplate.vue")
);
function handleContent(template_id: string) {
  router.push(`/administration/templates-easyreg/edit/${template_id}`);
}
function handleEdit(template_id: string, edit_type: string) {
  openModal(ManageTemplate, {
    template_id,
    edit_type,
  })
    // runs when modal is closed via confirmModal
    .then((data) => {
      console.log("success", data);
      load();
    })
    // runs when modal is closed via closeModal or esc
    .catch(() => {
      console.log("catch");
    });
}
async function handleDelete(template_id: string) {
  await DeleteEmailByTemplate(template_id);
  load();
}
function handleCreate() {
  openModal(ManageTemplate, {
    template_id: "NEW",
    edit_type: "NEW",
  })
    // runs when modal is closed via confirmModal
    .then((data) => {
      console.log("success", data);
    })
    // runs when modal is closed via closeModal or esc
    .catch(() => {
      console.log("catch");
    });
}
const load = async () => {
  try {
    const { rows, total } = await GetEmailTemplates(currentPage.value, "all");
    allData.value = rows;
  } catch (error) {}
};
onMounted(() => {
  (async () => {
    load();
  })();
});
</script>