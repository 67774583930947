import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/scss/app.scss"
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-rate/dist/vue-rate.css'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import "vue-multiselect/dist/vue-multiselect.css"
import { createModal } from '@kolirt/vue-modal'
import VueFeather from "vue-feather";
import Breadcrumbs from './layout/BreadCrumbs.vue';
import Datepicker from '@vuepic/vue-datepicker';
import { quillEditor } from "vue3-quill";
import rate from 'vue-rate'
import VueNumber from "vue-number-animation";
import Lightbox from 'vue-easy-lightbox'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import AosVue from "aos-vue";
import Multiselect from 'vue-multiselect'
import English from "@/core/locales/en.json"
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import vueAutocomplete from "@venegrad/vue3-autocomplete"
import { createI18n } from 'vue-i18n'
const i18n = createI18n({
    legacy: false,
    locale: 'English',
    messages: {
        English: English,
    }
})

const app = createApp(App);
app.config.globalProperties.$filters = {
    currency(value: number) {
        if (value) {
            let val = (value / 1).toFixed(2).replace(",", ".");
            return `$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        } else {
            return `$ 0.00`;
        }
    },
    filesize(size: number) {
        if (isNaN(size))
            size = 0;

        if (size < 1024)
            return size + ' Bytes';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' Kb';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' Mb';

        size /= 1024;

        if (size < 1024)
            return size.toFixed(2) + ' Gb';

        size /= 1024;

        return size.toFixed(2) + ' Tb';
    }
}
app.use(router)
    .use(createPinia())
    .use(i18n)
    .use(quillEditor)
    .use(rate)
    .use(PerfectScrollbar)
    .use(Lightbox)
    .use(AosVue)
    .use(VueNumber)
    .use(Autocomplete)
    .use(vueAutocomplete)
    .use(createModal({
        transitionTime: 200,
        animationType: 'slideDown',
        modalStyle: {
            padding: '5rem 2rem',
            align: 'center',
            'z-index': 201
        },
        overlayStyle: {
            'background-color': 'rgba(0, 0, 0, .5)',
            'backdrop-filter': 'blur(5px)',
            'z-index': 200
        }
    }))
    .component(VueFeather.name, VueFeather)
    .component('Breadcrumbs', Breadcrumbs)
    .component('Datepicker', Datepicker)
    .component('multiselect', Multiselect)
    .mount('#app')
